var _forEach = require('lodash/forEach');

import { dom, library } from '@fortawesome/fontawesome-svg-core';

import { faSeedling } from '@fortawesome/free-solid-svg-icons/faSeedling';
import { faTree } from '@fortawesome/free-solid-svg-icons/faTree';
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
import { faIndent } from '@fortawesome/free-solid-svg-icons/faIndent';
import { faHiking } from '@fortawesome/free-solid-svg-icons/faHiking';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faBurn } from '@fortawesome/free-solid-svg-icons/faBurn';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons/faCrosshairs';

import { faPagelines } from '@fortawesome/free-brands-svg-icons/faPagelines';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

var icons = [
    faSeedling,
    faTree,
    faFire,
    faBurn,
    faIndent,
    faPagelines,
    faTwitterSquare,
    faFacebookSquare,
    faInstagram,
    faHiking,
    faInfoCircle,
    faFileSignature,
    faCheckCircle,
    faExclamationTriangle,
    faPlusCircle,
    faAsterisk,
    faBullhorn,
    faCrosshairs
];

_forEach(icons, function(icon) {
    library.add(icon);
});

dom.watch();
