import * as Sentry from '@sentry/browser';
Sentry.init({
        dsn: 'https://36585d8e00b149698d3a4a0063a172f1@sentry.io/1358249',
    release: 'ag-browser@1.0.0'
});

import '../../vendor/shards/dist/shards';
import './jquery-actions';
import './fa';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import 'jquery.are-you-sure';
import 'jquery.are-you-sure/ays-beforeunload-shim.js'

if (module.hot) {
    module.hot.accept();
}

// Client side form validation
(function() {
    'use strict';
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        _filter(forms, function(form) {
            form.addEventListener('submit', function(event) {
                // Will submit with jQuery...ugh
                event.preventDefault();
                event.stopPropagation();

                if (form.checkValidity() === false) {
                    var errorElements = document.querySelectorAll("input.form-control:invalid, input.custom-control-input:invalid, textarea.form-control:invalid, select.form-control:invalid, #email_retype.is-invalid");
                    _forEach(errorElements, function (element) {
                        _forEach(element.parentNode.childNodes, function (node) {
                            if (node.className == 'valid-feedback') {
                                node.className = 'invalid-feedback';
                            }
                        });
                    });
                    if (errorElements.length !== 0) { // On successful validation, do not scroll
                        $('html, body').animate({
                            scrollTop: $(errorElements[0]).offset().top - 100
                        }, 300);
                    }
                    form.classList.add('was-validated');
                } else {
                    // Form field validated, next check for reCaptcha
                    var formData = new FormData(form);

                    $.ajax({
                        method: "POST",
                        url: form.action,
                        data: formData,
                        contentType: false,
                        processData: false
                    }).fail(function (response) {
                        if (response.responseJSON.reason === 'reCaptcha empty') {
                            alert("Please prove you are not a robot before sending!");
                        } else if (response.responseJSON.reason === 'reCaptcha challenge failed') {
                            window.location.href = response.responseJSON.redirect;
                        } else {
                            Sentry.captureMessage(response.responseText);
                            window.location.href = response.responseJSON.redirect;
                        }
                    }).done(function (response) {
                        window.location.href = response.redirect;
                    });
                }
            }, false);

            // Prevent navigating away from page without confirmation
            $(form).areYouSure();
        });

        let email = $('#email_address');
        let emailMatch = $('#email_retype');

        let compare = function () {
            if (!(emailMatch.length === 0)) {
                if (email.val() === emailMatch.val()) {
                    emailMatch[0].setCustomValidity('');
                    emailMatch.removeClass('is-invalid');
                } else {
                    emailMatch[0].setCustomValidity('no-match');
                    emailMatch.addClass('is-invalid');
                }
            }
        };

        email.keyup(function () {
            compare();
        });
        emailMatch.keyup(function () {
            compare();
        })
    }, false);

})();
