/**
 * FluidForm.js
 *
 * Each function below will set up jQuery callbacks for any /form-schema file's field objects.
 * jquery-actions.js is built by gulp 'build-jquery-triggers' to contain a line for each action/event pair
 * specified in the /form-schem/*.js module exports - each function call in that file will reference functions here.
 *
 */

import _forEach from 'lodash/forEach';
import _diff from 'lodash/difference';
import _includes from 'lodash/includes';
import _keys from 'lodash/keys';

function show (j) {
    j.removeAttr('hidden');
    j.animate({opacity: 100}, 250, () => {
        j.removeAttr('disabled');
    });
}

function hide (j) {
    j.prop('disabled', true);
    j.animate({opacity: 0}, 250, () => {
        j.prop('hidden', true);
    });
}

/**
 * ToggleChanged
 *
 */

export function ToggleChanged (sender, data) {
    $('#'+sender).change(function(event) {
        if ($(event.currentTarget).is(':checked')) {
            _forEach(_keys(data), function(target) {
                _forEach(data[target], function(action) {
                    if (action.event === 'on') {
                        if (action.action === 'show') {
                            show($('#'+target));
                        } else if (action.action === 'hide') {
                            hide($('#'+target));
                        }
                    }
                });
            });
        } else {
            _forEach(_keys(data), function(target) {
                _forEach(data[target], function(action) {
                    if (action.event === 'off') {
                        if (action.action === 'show') {
                            show($('#'+target));
                        } else if (action.action === 'hide') {
                            hide($('#'+target));
                        }
                    }
                });
            });
        }
    })
}


/**
 * DatepickerHook
 *
 */

export function DatepickerHook (elemId) {
    $('#'+elemId).datepicker();
}

/**
 * MultiRadioChanged
 *
 */

export function MultiRadioChanged (sender, data) {
    $('input[type=radio][name='+sender+']').change(function(event) {
        _forEach(_keys(data), function(target) {
            _forEach(data[target], function(action) {
                if (action.event === event.currentTarget.value) {
                    if (action.action === 'show') {
                        show($('#'+target));
                    } else if (action.action === 'hide') {
                        hide($('#'+target));
                    }
                }
            })
        });
    })
}


/**
 * SelectChangedToValue
 *
 */

export function SelectChangedToValue (sender, data) {
    $(document.body).on('change', "#" + sender, function (e) {
        _forEach(_keys(data), function(target) {
            _forEach(data[target], function(action) {
                if (action.event === $("#" + sender + " option:selected").val()) {
                    if (action.action === 'show') {
                        show($('#' + target));
                    } else if (action.action === 'hide') {
                        hide($('#' + target));
                    }
                }
            })
        });
    });
}


/**
 * MultiSelectChangedToValues
 *
 */

export function MultiSelectChangedToValues (sender, data) {
    var lastValues = [];
    
    // parse :on / :off events from data
    var myListeners = [];
    _forEach(_keys(data), function(target) {
        _forEach(data[target], function(action) {
            var myListener = {};
            var keys = action.event.split(':');
            myListener.sender = keys[0];
            myListener.event = keys[1];
            myListener.action = action.action;
            myListener.target = target;
            
            myListeners.push(myListener);
        })
    });
    
    $('#'+sender).change(function (event) {
        var next = $('#'+sender).val();
        
        // Create new diff array
        var diff = _diff(next, lastValues);
        
        var events = [];
    
        // generate 'on' events
        _forEach(diff, function(value) {
            var ev = {};
            ev.sender = value;
            ev.event = 'on';
            events.push(ev);
        });
        
        // grab unchanged events
        var unchanged = _diff(next, diff);
        
        // generate 'off' events
        var offed = _diff(lastValues, unchanged);
        _forEach(offed, function(value) {
            var ev = {};
            ev.sender = value;
            ev.event = 'off';
            events.push(ev);
        });
        
        // go through event list and compare to listeners
        _forEach(events, function(event) {
            _forEach(myListeners, function(myListener) {
                if (myListener.sender === event.sender) {
                    if (myListener.event === event.event) {
                        if (myListener.action === 'show') {
                            show($('#' + myListener.target));
                        }
                        else if (myListener.action === 'hide') {
                            hide($('#' + myListener.target));
                        }
                    }
                }
            });
        });
        
        // Save current array for next change event
        lastValues = next;
    });
    
}

/**
 * NumberChanged
 *
 */

export function NumberInputChanged (sender, data) {
    
    // parse > / < events from data
    var ops = [];
    _forEach(_keys(data), function(target) {
        _forEach(data[target], function(action) {
            var op = {};
            var keys = action.event.split(' ');
            op.comparator = keys[0];
            op.operand = keys[1];
            op.action = action.action;
            op.target = target;
            
            ops.push(op);
        })
    });
    
    $('#'+sender).change(function(event) {
        var val = $(event.currentTarget).val();
        
        _forEach(ops, function(op) {
            switch (op.comparator) {
                case '<':
                    if (val < op.operand) {
                        if (op.action === 'show') {
                            show($('#' + op.target));
                        } else if (op.action === 'hide') {
                            hide($('#' + op.target));
                        }
                    }
                    break;
                    
                case '=':
                    if (val === op.operand) {
                        if (op.action === 'show') {
                            show($('#' + op.target));
                        } else if (op.action === 'hide') {
                            hide($('#' + op.target));
                        }
                    }
                    break;
                    
                case '>':
                    if (val > op.operand) {
                        if (op.action === 'show') {
                            show($('#' + op.target));
                        } else if (op.action === 'hide') {
                            hide($('#' + op.target));
                        }
                    }
                    break;
            }
        });
    })
}