import * as FForm from './FluidForm';
FForm.ToggleChanged('ff_contact_by_phone', {"phone_number":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.ToggleChanged('ui_toggle_4', {"re_0_section_2":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.ToggleChanged('ui_toggle_5', {"re_0_section_3":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.ToggleChanged('ui_toggle_6', {"re_1_section_2":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.ToggleChanged('ui_toggle_7', {"re_1_section_3":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.ToggleChanged('ui_toggle_8', {"ref_2":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.MultiRadioChanged('previous_experience', {"previous_experience_description":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('rq_4', {"fs_rq_4_description":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('boss_experience', {"has_fb_ex":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('ff_id_fn', {"id_fn_description":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('ff_id_vm', {"id_vm_exp":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('ff_id_dis', {"id_dis_exp":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.SelectChangedToValue('ff_preferred_pronoun', {"other_pronoun":[{"event":"other","action":"show"},{"event":"her","action":"hide"},{"event":"him","action":"hide"},{"event":"them","action":"hide"},{"event":"not say","action":"hide"}]});
FForm.MultiSelectChangedToValues('extra_certs', {"other_certs_description":[{"event":"Other:off","action":"hide"},{"event":"Other:on","action":"show"}]});
FForm.NumberInputChanged('years_firefighting_experience', {"rookie_ff_spec_1":[{"event":"= 0","action":"show"},{"event":"= 1","action":"hide"},{"event":"> 1","action":"hide"}],"rookie_ff_spec_2":[{"event":"= 0","action":"show"},{"event":"= 1","action":"hide"},{"event":"> 1","action":"hide"}],"experienced_ff_spec_1":[{"event":"= 0","action":"hide"},{"event":"= 1","action":"show"},{"event":"> 1","action":"show"}]});
FForm.DatepickerHook('ff_available_from_date');
FForm.DatepickerHook('ff_available_to_date');
FForm.ToggleChanged('contact_by_phone', {"phone_number":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.ToggleChanged('ui_toggle_1', {"re_0_section_2":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.ToggleChanged('ui_toggle_2', {"re_0_section_3":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.ToggleChanged('ui_toggle_3', {"ref_2":[{"event":"on","action":"show"},{"event":"off","action":"hide"}]});
FForm.MultiRadioChanged('rq_5', {"fs_rq_5_description":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('has_driver_training', {"driver_desc_section":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('has_brush_saw_experience', {"brushing_desc_section":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('has_chainsaw_experience', {"chainsaw_desc_section":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('id_fn', {"id_fn_description":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('id_vm', {"id_vm_exp":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('id_dis', {"id_dis_exp":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.MultiRadioChanged('id_lgbtq', {"id_lgbtq_exp":[{"event":"Yes","action":"show"},{"event":"No","action":"hide"}]});
FForm.SelectChangedToValue('preferred_pronoun', {"other_pronoun":[{"event":"other","action":"show"},{"event":"her","action":"hide"},{"event":"him","action":"hide"},{"event":"them","action":"hide"},{"event":"not say","action":"hide"}]});
FForm.MultiSelectChangedToValues('areas_of_interest', {"aoi_crew_leader":[{"event":"Crew Leader:off","action":"hide"},{"event":"Crew Leader:on","action":"show"}],"aoi_vehicle_driver":[{"event":"Crew Vehicle Driver:off","action":"hide"},{"event":"Crew Vehicle Driver:on","action":"show"}],"aoi_brushing_etc":[{"event":"Brushing/Spacing/Stand Tending:off","action":"hide"},{"event":"Brushing/Spacing/Stand Tending:on","action":"show"}]});
FForm.NumberInputChanged('years_planting_experience', {"rookie_planter_spec_1":[{"event":"= 0","action":"show"},{"event":"= 1","action":"hide"},{"event":"> 1","action":"hide"}],"rookie_planter_spec_2":[{"event":"= 0","action":"show"},{"event":"= 1","action":"hide"},{"event":"> 1","action":"hide"}],"experienced_planter_spec_1":[{"event":"= 0","action":"hide"},{"event":"= 1","action":"show"},{"event":"> 1","action":"show"}]});
FForm.DatepickerHook('available_from_date');
FForm.DatepickerHook('available_to_date');
